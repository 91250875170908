



























import Vue from 'vue';
import DefaultLayout from './default.vue';
import TheAboutUs from '~/components/AboutUs/TheAboutUs.vue';
import { UPDATE_WORDPRESS_AUTHENTICATION } from '~/constants';

export default Vue.extend({
  name: 'HealthLayout',

  components: { DefaultLayout, TheAboutUs },

  async fetch () {
    try {
      const { isAuthenticatedInWordpress } = this.$store.state as IRootState;
      if (isAuthenticatedInWordpress) {
        return;
      }

      const response = await this.$ironMaidenApi.fetchLastNArticles(1);
      if (response.status === 200) {
        const { isUserLoggedIn } = response.data;
        this.$store.commit(UPDATE_WORDPRESS_AUTHENTICATION, { isUserLoggedIn });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error in health layout fetch', err);
    }
  },

  head (): any {
    return {
      meta: [
        {
          hid: 'theme-color',
          name: 'theme-color',
          // !86c04jx42 поменять
          // content: '#2962f9',
          content: '#e85495',
        },
      ],
    };
  },

  computed: {
    isHealthHome (): boolean {
      return this.$route.name === 'health-home';
    },
    computedClass (): Record<string, any> {
      return {
        'health-service-container': this.$route.name === 'health-services',
      };
    },
  },
});
