
























import Vue from 'vue';
import DefaultAmpLayout from './default.amp.vue';

export default Vue.extend({
  name: 'HealthAmpLayout',

  components: { DefaultAmpLayout },

  head (): any {
    return {
      meta: [
        {
          hid: 'theme-color',
          name: 'theme-color',
          // !86c04jx42 поменять
          // content: '#2962f9',
          content: '#e85495',
        },
      ],
    };
  },
});
